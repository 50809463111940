<template>
  <list
    :title="$t('Contacts.myContacts')"
    icon="mdi-card-account-phone-outline"
    :add-text="$t('Contacts.add')"
    item-key="cuentaContactoId"
    url="api/v1/private/contacts"
    @add="() => $router.push(`/entities/${$route.params.entidadId}/account/add-contact`).catch(() => {})"
  >
    <template #item="{ item, remove, loading }">
      <div class="pa-4 d-flex align-center flex-wrap">
        <div class="flex-1 mr-3">
          <p class="mb-0">
            {{ item.nombreContacto }}
          </p>
        </div>
        <div class="flex-1 mr-3">
          <!-- <p class="mb-0">{{ item.type }}</p> -->
        </div>

        <div class="mr-3">
          <v-btn
            icon
            color="grey"
            :disabled="loading"
            @click="() => $router.push(`/entities/${$route.params.entidadId}/account/contacts/${item.cuentaContactoId}`).catch(() => {})"
          >
            <v-icon>mdi-lead-pencil</v-icon>
          </v-btn>

          <v-btn icon color="grey" :disabled="loading" @click="() => remove()">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </list>
</template>

<script>
import List from "@/components/List";

export default {
  components: {
    List,
  }
};
</script>